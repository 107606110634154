import React from "react"
import useFirebase from "../components/useFirebase"
import SEO from "../components/seo"
import { actionCodeSettings } from "../components/firebasehelper"
import {
  Box,
  Text,
  FormControl,
  Input,
  FormLabel,
  Button,
  useToast,
  Img,
  Center,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import betaLogo from "../images/lokalcommissionslogobeta2.png"

const Login = () => {
  const firebase = useFirebase()
  const toast = useToast()
  const { handleSubmit, register } = useForm()
  const signIn = value => {
    console.log(value.email)
    firebase
      .auth()
      .fetchSignInMethodsForEmail(value.email)
      .then(signInMethods => {
        signInMethods.length
          ? firebase
              .auth()
              .sendSignInLinkToEmail(value.email, actionCodeSettings)
              .then(() => {
                localStorage.setItem("emailaddress", value.email)
                console.log("Sent Email")
                toast({
                  title: "Email Link Sent",
                  description: "Check your email inbox for your sign link.",
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                })
              })
              .catch(error => {
                console.log(error)
              })
          : toast({
              title: "Email Address Invalid",
              description:
                "Please check that the email you entered has a valid account.",
              status: "error",
              duration: 9000,
              isClosable: true,
            })
      })
      .catch(error => {
        console.log(error)
      })
  }
  return (
    <Box
      px={10}
      bg={"#F2D6D3"}
      overflow={"hidden"}
      minH={"100vh"}
    >
      <SEO title="Login" />
      <Img mt={7} boxSize={"106px"} src={betaLogo} align={"start"} />
      
      <form onSubmit={handleSubmit(signIn)}>
        <Box
          p={10}
          bg={"white"}
          overflow={"hidden"}
          borderRadius={"md"}
          my={5}
        ><Text>Type in your email address to get your magic link.</Text>
          <FormControl>
            <FormLabel htmlFor="email">Email address</FormLabel>
            <Input
              ref={register}
              name="email"
              type="email"
              id="email"
              placeholder="email@example.com"
              aria-describedby={"Email Address"}
            />
          </FormControl>
          <Center>
            <Button type={"submit"} m={5} bg={"#F6DD8F"} fontWeight={"bold"}>
              Login
            </Button>
          </Center>
        </Box>
      </form>
    </Box>
  )
}

export default Login
