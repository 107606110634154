import React, { useEffect } from "react"
import { Router } from "@reach/router"
import Start from "../components/start"
import CommissionPage from "../pages/c"
import Dashboard from "./dashboard"
import Verify from "./verify"
import Login from "./login"
import Privacy from "./privacy"
import useFirebase from "../components/useFirebase"
import Condition from "./terms"

const IndexPage = () => {
  const firebase = useFirebase()
  useEffect(() => {
    firebase ? firebase.analytics() : console.log("Still loading firebase")
  }, [firebase])
  return (
    
      <Router>
        <Start path="/" />
        <CommissionPage path="c/:artistId" />
        <Dashboard path="dashboard" />
        <Verify path="verify" />
        <Login path="login" />
        <Condition path="terms" />
        <Privacy path="privacy" />
      </Router>
  )
}

export default IndexPage
