import { Center, Heading, Text, Box, Img } from "@chakra-ui/react"
import betaLogo from "../images/lokalcommissionslogobeta2.png"
import React, { ReactElement } from "react"

const Condition: React.FC<any> = (): ReactElement => {
  return (
    <Box
      px={10}
      bg={"#F2D6D3"}
      overflow={"hidden"}
      minH={"100vh"}
    >
      <Img mt={7} boxSize={"106px"} src={betaLogo} />
      <Center>
        <Heading mt={5}>Terms of Use</Heading>
      </Center>
      <Box width={{ base: "90%", md: "70%" }} mx={"auto"}>
        <Text>
          <Heading>Acceptance of these terms</Heading>
          Please read these Terms and Conditions (the “Terms”) carefully. These
          Terms, our Privacy Policy and all other policies related to the
          Website and Services form the entire legal agreement between you and
          us. By creating an Account, using the Services, and/or purchasing a
          Plan you will be taken to have read, understood and agree to be bound
          by these Terms. You may also accept these Terms by clicking to accept
          the Terms where this option is made available to you. If you do not
          agree with the Terms, you must stop using the Website, or any
          Services, immediately. You may not use the Services or accept the
          Terms if you are not of legal age to enter into a binding contract
          with us. If you are agreeing to these Terms or registering an Account
          on behalf of a company or organisation, you warrant and agree that you
          have the authority to bind the company or organisation to these Terms
          and that the company or organisation will be bound by these Terms. We
          reserve the right to change the Terms at our sole discretion at any
          time by reasonable notice to you including by posting revised Terms on
          the Website. Any changes to the Terms take immediate effect and shall
          be binding on you from the date of their posting on the Website.
          <Heading>Registration</Heading>
          In order to access the Services, you must first register for an
          account through the Website (“Account”). As part of the registration
          process, and as part of your continued use of the Services, you will
          be required to provide some personal information including your email
          address and a display name. You warrant that any information provided
          to us during the Account registration process is accurate, correct and
          up to date. You are solely responsible for protecting your
          registration information. Sharing your registration information with
          any individuals or third party is strictly prohibited and may result
          in the immediate cancellation of your Subscription and Services. If
          your registration information changes, you must promptly update your
          Account information to reflect those changes. You are responsible for
          maintaining the confidentiality and integrity of your Account and
          password and are responsible for all use and activity carried out on
          your Account. You must immediately notify us of any unauthorized use
          of your registration information and/or any breach of security of
          which you become aware. When you register for a new Account, you will
          be able to access the full features of a paid Plan for 14 days at no
          charge. At the end of the free trial, we may at our discretion offer
          to extend the period of your free trial, which we will do by notifying
          you via email.
          <Heading>Subscription to use the Services</Heading>
          Once you have registered an Account or at the end of the free trial,
          you will be required to purchase a subscription (“Subscription”) and
          pay the applicable fee for the selected Subscription (“Subscription
          Fee”) to continue using some of the Services and the Plans. By
          purchasing a Subscription, you acknowledge and agree that it is your
          responsibility to ensure that the Subscription you select is suitable
          for your use. Your subscription will start when you have paid the
          Subscription Fee and will continue until the end of the subscription
          period you have selected (“Subscription Period”). At the end of the
          initial Subscription Period, your Subscription will automatically
          renew for a further Subscription Period on the same terms as the
          initial period, unless you cancel your Subscription before it renews.
          We may but are not obligated to send you any renewal notices or other
          reminders at the end of the Subscription Period. It is your sole
          responsibility to monitor your Account and promptly advise us of any
          changes to your Account or Subscription, including cancellations.
          <Heading>Relationship between Members and respondents</Heading>
          We are not a party to any contractual relationship between you and
          your customers or form respondents. You are solely responsible for all
          aspects of your relationship with your customers or form respondents,
          including: entering into suitable contractual arrangements; providing
          products or services, and honouring any bookings made through the
          Services; and collecting payment and arranging refunds, if applicable.
          You are also responsible for ensuring that your customers or form
          respondents are aware that their personal information may be or is
          being provided to and processed through the Website and Services,
          including, if necessary, obtaining their consent to such usage.
          <Heading>Links to Third Party Websites</Heading>
          The Website may include links to third party websites. We do not
          control or endorse any third-party websites. You agree and acknowledge
          that we are not responsible for: the availability of third-party
          websites; any content, advertising, products or other materials on or
          available from these third-party websites; any damage or loss directly
          or indirectly caused or alleged to be caused by or in connection with
          your use of or reliance on any such content, goods, or services
          available on or through any third-party website.
          <Heading>Privacy</Heading>
          We take your privacy seriously and any information provided through
          your use of the Website or Services are subject to our Privacy Policy,
          which is available on the Website.
          <Heading>General Disclaimer</Heading>
          Use of the Website and Services is solely at your own risk. The
          Website and Services are provided to you "as is" and "as available"
          without warranty or representation of any kind. We make no warranty or
          representation that any result or objective can or will be achieved
          using the Website or Services, or that the Website or Services will be
          fit for purpose, continuous, uninterrupted, accurate, fault-free,
          virus-free, secure or accessible at all times. None of the affiliates,
          directors, officers, employees, agents, contributors and licensors of
          Lokal Creatives make any express or implied representation or warranty
          about the Services or any products or Services referred to on the
          Website. This includes (but is not restricted to) loss or damage you
          might suffer as a result of: failure of performance, error, omission,
          interruption, deletion, defect, failure to correct defects, delay in
          operation or transmission, computer virus or other harmful component,
          loss of data, communication line failure, unlawful third-party
          conduct, or theft, destruction, alteration, or unauthorized access to
          records; the accuracy, suitability, or currency of any information on
          the Website, the Services, or any of its Services related products
          (including third party material and advertisements on the Website);
          costs incurred as a result of you using the Website, the Services or
          any of our products; and the Services or operation in respect to links
          which are provided for your convenience.
          <Heading>Limitation of Liability</Heading>
          Our total liability arising out of or in connection with the Services
          or these Terms, however arising, including under contract, tort
          (including negligence), in equity, under statute or otherwise, will be
          limited to the resupply of the Services to you. You expressly
          understand and agree that we and our employees, agents, contributors
          and licensors shall not be liable to you for any direct, indirect,
          incidental, special consequential or exemplary damages which may be
          incurred by you, however caused. This shall include, but is not
          limited to, any loss of profit (whether incurred directly or
          indirectly), any loss of goodwill or business reputation and any other
          intangible loss. To the extent permitted by law, all terms,
          guarantees, warranties, representations or conditions which are not
          expressly stated in the Terms are excluded.
          <Heading>Termination</Heading>
          The Terms will continue to apply until terminated by either you or us
          as set out below. If you want to terminate the Terms, you may do so at
          any time by cancelling your Subscription through your Account
          settings. We may at any time, terminate the Terms with you, or suspend
          or deny your access to any or all of the Website or Services, if: you
          have breached any provision of the Terms or intend to breach any
          provision; you breach any applicable law; we are required to do so by
          law; your conduct impacts our name, goodwill, reputation and/or
          violates the rights of a third party; or the provision of the Services
          to you by us is, in our opinion, no longer commercially viable. We
          reserve the right to cease operating the Website and Services, without
          notice and for any reason.
        </Text>
      </Box>
    </Box>
  )
}

export default Condition
