import React, { useEffect, useState } from "react"
import { Box, Center, Spinner, Img } from "@chakra-ui/react"
import { navigate } from "gatsby"
import useFirebase from "../components/useFirebase"
import betaLogo from "../images/lokalcommissionslogobeta2.png"

const Verify = () => {
  const firebase = useFirebase()
  const [link, setLink] = useState()
  const [email, setEmail] = useState()
  useEffect(() => {
    setLink(localStorage.getItem("link"))
    setEmail(localStorage.getItem("emailaddress"))
  }, [])
  useEffect(() => {
    const emailing = () => {
      console.log(email)
      firebase.auth().isSignInWithEmailLink(window.location.href)
        ? email
          ? firebase
              .auth()
              .signInWithEmailLink(email, window.location.href)
              .then(user => {
                // Clear email from storage.
                localStorage.removeItem("emailaddress")
                console.log(window.location.href)
                link
                  ? firebase
                      .auth()
                      .currentUser.updateProfile({ displayName: link })
                  : console.log(
                      "No link in storage, don't overwrite display name"
                    )
                console.log(user)
                localStorage.removeItem("link")
                navigate("/dashboard/")
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
              })
              .catch(error => {
                console.log(error)
              })
          : console.log("nomail")
        : console.log("Not a valid sign in with email link")
    }
    const checking = () => {
      const user = firebase.auth().currentUser
      console.log("Firebase initialised")
      console.log(link)
      console.log(email)
      console.log(user)
      emailing()
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      // The client SDK will parse the code from the link for you.
    }
    firebase ? checking() : console.log("Firebase not init")
  }, [firebase, email, link])
  return (
    <Box px={10} bg={"#F2D6D3"} overflow={"hidden"} minH={"100vh"}>
      <Img mt={7} boxSize={"106px"} src={betaLogo} align={"start"} />
      <Center>
        <Spinner size={"xl"} mt={20} />
      </Center>
    </Box>
  )
}
export default Verify
