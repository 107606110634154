import React, { useState } from "react"
import { useForm } from "react-hook-form"
import SEO from "../components/seo"
import {
  Heading,
  HStack,
  VStack,
  Stack,
  Img,
  ModalHeader,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  Box,
  Text,
  FormErrorMessage,
  Checkbox,
  Link,
  Center,
  TabPanel,
  TabPanels,
  Tabs,
  ButtonGroup,
  useDisclosure,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react"
import { nanoid } from "nanoid"
import { actionCodeSettings } from "./firebasehelper"
import { navigate, Link as GatsbyLink } from "gatsby"
import betaLogo from "../images/lokalcommissionslogobeta2.png"
import artPic from "../images/KAL6.png"
import gridPic from "../images/grid_screenshot.png"
import mailPic from "../images/mailing_list_lokal.png"
import lokalScreenshot from "../images/lokal_screenshot.png"
import menuPic from "../images/commission_screenshot.png"
import useFirebase from "./useFirebase"

const Start = ({ location }) => {
  const firebase = useFirebase()
  const [id] = useState(nanoid(26))
  const [loading, setLoading] = useState(false)
  const [loadSign, setLoadSign] = useState(false)
  const [created, setCreate] = useState(false)
  const [reSent, setReSent] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tabIndex, setTabIndex] = useState(0)
  const [artistEmail, setArtistEmail] = useState(null)
  const link = location.pathname
  console.log(link)
  const storeArtist = async (data, name) => {
    data.joined = Date.now()
    data.contact = {}
    data.name = name
    //Changed to have cleaner user links.
    await firebase
      .firestore()
      .collection("usernames")
      .doc(name.toLowerCase())
      .set({ id: id })
    await firebase.firestore().collection("artists").doc(id).set(data)
  }
  const validateDisplayName = name => {
    //Checks if username already exists.

    return firebase
      .firestore()
      .collection("usernames")
      .doc(name.toLowerCase())
      .get()
      .then(doc => {
        if (doc.exists) {
          console.log("Username is taken.")
          return "Username is already taken."
        } else {
          console.log("Username not taken.")
          return true
        }
      })
  }
  const handleTabChange = index => {
    setTabIndex(index)
  }
  const { handleSubmit, register, errors } = useForm({ mode: "onBlur" })
  const VerifyScreen = () => (
    <Center>
      <Box minH={300}>
        <Text mt={40}>
          A link has been sent to your email address.<br></br>Please verify your
          email address to continue.
        </Text>
        <Button
          onClick={() => reSend()}
          isLoading={reSent}
          bg={"#F6DD8F"}
          fontWeight={"bold"}
          size={"sm"}
        >
          RESEND LINK
        </Button>
      </Box>
    </Center>
  )
  //const SignForm
  const signIn = () => {
    setLoadSign(true)
    firebase.auth().onAuthStateChanged(user => {
      user ? navigate("/dashboard/") : navigate("/login/")
      console.log("Checked user")
    })
  }
  const reSend = values => {
    firebase
      .auth()
      .sendSignInLinkToEmail(artistEmail, actionCodeSettings)
      .then(() => {
        setReSent(true)
      })
      .catch(error => console.log(error))
  }
  const onSubmit = values => {
    const userlink = values.name //Changed to just use username instead of attaching unique id
    console.log(userlink)
    setLoading(true)
    //window ?
    localStorage.setItem("link", userlink)
    setArtistEmail(values.email)
    firebase
      .auth()
      .sendSignInLinkToEmail(values.email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailaddress", values.email)
        storeArtist(values, userlink)
        setCreate(true)
        onClose()
        console.log("Sent email to " + values.email)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
    // : console.log("Not browser")
  }
  return (
    <Box px={1} bg={"#F2D6D3"} overflow={"hidden"} minH={"100vh"}>
      <SEO title="How Artists Make Money" />
      {!created ? (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader></ModalHeader>

              <ModalBody>
                <HStack>
                  <Img mb={1} boxSize={"106px"} src={betaLogo} />
                  <Text>
                    All you need is an email address and a display name.
                  </Text>
                </HStack>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl isRequired isInvalid={errors.email?.message}>
                    <FormLabel htmlFor="email">Email address</FormLabel>
                    <FormHelperText id="name-helper-text">
                      So that you can log in.
                    </FormHelperText>
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                    <Input
                      ref={register({
                        required: "Your email address is required",
                      })}
                      name="email"
                      type="email"
                      id="email"
                      placeholder=""
                      aria-describedby={"Email Address"}
                    />
                  </FormControl>
                  <FormControl isRequired isInvalid={errors.name?.message}>
                    <FormLabel>Display Name</FormLabel>
                    <FormHelperText id="name-helper-text">
                      What should we call you?
                    </FormHelperText>
                    <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                    <Input
                      ref={register({
                        required: "Your display name is required",
                        validate: validateDisplayName,
                        minLength: {
                          value: 4,
                          message: "Display name must be at least 4 digits.",
                        },
                        maxLength: {
                          value: 20,
                          message: "Display name must be less than 21 digits.",
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9]*$/gm,
                          message:
                            "Your display name should be one word, without spaces or special characters.",
                        },
                      })}
                      name="name"
                      id="name"
                      aria-describedby="name-helper-text"
                      mb={10}
                    />
                  </FormControl>
                  <Center>
                    <FormControl
                      isRequired
                      isInvalid={errors.PrivacyConsent?.message}
                    >
                      <Checkbox
                        ref={register({
                          required:
                            "You need to confirm that you have read and agree to our Privacy Policy",
                        })}
                        name={"PrivacyConsent"}
                      >
                        I have read and agree to the{" "}
                        <Link as={GatsbyLink} to={"/privacy"} color={"#F68F8F"}>
                          Privacy Policy
                        </Link>
                      </Checkbox>
                      <FormErrorMessage>
                        {errors.PrivacyConsent?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Center>
                  <Center>
                    <FormControl
                      isRequired
                      isInvalid={errors.TermsConsent?.message}
                    >
                      <Checkbox
                        ref={register({
                          required:
                            "You need to confirm that you have read and agree to our Terms and Conditions",
                        })}
                        name={"TermsConsent"}
                      >
                        I have read and agree to the{" "}
                        <Link as={GatsbyLink} to={"/terms"} color={"#F68F8F"}>
                          Terms and Conditions
                        </Link>
                      </Checkbox>
                      <FormErrorMessage>
                        {errors.TermsConsent?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Center>
                  <HStack
                    mt={5}
                    justify={{ base: "center", md: "space-between" }}
                    wrap={"wrap-reverse"}
                  >
                    <Link
                      as={GatsbyLink}
                      to={"/login"}
                      textDecoration={"underline"}
                      color={"grey.300"}
                    >
                      Already have an account?
                    </Link>
                    <Button
                      type={"submit"}
                      isLoading={loading}
                      bg={"#F6DD8F"}
                      fontWeight={"bold"}
                      size={"sm"}
                    >
                      CREATE YOUR PAGE
                    </Button>
                  </HStack>
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>

          <Tabs
            defaultIndex={0}
            variant={"unstyled"}
            isFitted
            index={tabIndex}
            onChange={handleTabChange}
          >
            <Center>
              <HStack
                mt={2}
                justify={{ base: "auto", md: "space-between" }}
                bg={"white"}
                borderRadius={"md"}
                wrap={"wrap"}
                size={"sm"}
                pb={1}
                w={{ lg: "100vw" }}
              >
                <Img mb={1} boxSize={"106px"} src={betaLogo} />
                <ButtonGroup size={"sm"} color={"black"} isAttached>
                  <Button
                    bg={"white"}
                    fontWeight={"bold"}
                    size={"sm"}
                    onClick={() => handleTabChange(0)}
                  >
                    HOME
                  </Button>
                  {/*
                <Button
                  bg={"white"}
                  fontWeight={"bold"}
                  size={"sm"}
                  onClick={() => handleTabChange(1)}
                >
                  ABOUT
                </Button>*/}
                  <Button
                    bg={"white"}
                    fontWeight={"bold"}
                    size={"sm"}
                    onClick={() =>
                      window.open(
                        "https://www.lokalcr.com/blog",
                        "_blank",
                        "noopener noreferrer"
                      )
                    }
                  >
                    BLOG
                  </Button>
                </ButtonGroup>
                <ButtonGroup mt={0} spacing={"-2"} size={"sm"} mr={3}>
                  <Button
                    onClick={() => signIn()}
                    isLoading={loadSign}
                    fontWeight={"bold"}
                  >
                    LOGIN
                  </Button>

                  <Button onClick={onOpen} bg={"#F6DD8F"} fontWeight={"bold"}>
                    CREATE YOUR PAGE
                  </Button>
                </ButtonGroup>
              </HStack>
            </Center>
            <TabPanels overflow={"hidden"}>
              <TabPanel>
                <Box
                  py={15}
                  px={3}
                  bg={"white"}
                  overflow={"hidden"}
                  borderRadius={"md"}
                  minH={[350, 400]}
                  maxW={{ base: "100%", lg: "80%" }}
                  mx={"auto"}
                >
                  <HStack
                    wrap={{ base: "wrap-reverse", md: "nowrap" }}
                    justify={"space-around"}
                  >
                    <VStack my={"auto"}>
                      <Heading size={"2xl"} wordBreak={"keep-all"}>
                        SELL{" "}
                        <Text as={"span"} color={"#F68F8F"}>
                          ART
                        </Text>{" "}
                        AND GROW YOUR
                        <Text size={"lg"} color={"#F68F8F"}>
                          AUDIENCE
                        </Text>
                      </Heading>

                      <Text w={"90%"} my={5}>
                        Easy-to-use memberships and eCommerce for artists. Start
                        a Lokal page. Build your community. Make money from
                        subscriptions and commissions. Publishing content is
                        free, we only make money when you do.{" "}
                      </Text>

                      <Button
                        onClick={onOpen}
                        size={"lg"}
                        fontWeight={"bold"}
                        g={"#F6DD8F"}
                        alignSelf={"center"}
                      >
                        GET STARTED{" "}
                      </Button>
                    </VStack>
                    <Img
                      src={artPic}
                      boxSize={"20em"}
                      my={"auto"}
                      objectFit={"cover"}
                    />
                  </HStack>
                </Box>
                <Center>
                  <Box p={2} m={2} bg={"#F6DD8F"} borderRadius={"md"}>
                    <Heading>ABOUT LOKAL</Heading>
                  </Box>
                </Center>
                <Stack
                  direction={"row"}
                  wrap={"wrap"}
                  justify={"center"}
                  bg={"white"}
                  m={5}
                  px={5}
                  spacing={0}
                  align={"center"}
                >
                  <Box
                    p={10}
                    bg={"white"}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "90%", md: "60%", lg: "35%" }}
                    borderWidth={5}
                    my={5}
                  >
                    <Center>
                      <Heading size={"md"}>EVERYTHING IN ONE PLACE</Heading>
                    </Center>
                    <Text>
                      Create a Lokal page in 60 seconds and you can start
                      selling access to art content, grow your mailing list and
                      sell commissions to grow your income. Do everything from
                      your beautiful Lokal page
                    </Text>
                    <Center>
                      <Button
                        onClick={onOpen}
                        bg={"#F6DD8F"}
                        fontWeight={"bold"}
                      >
                        CREATE YOUR PAGE
                      </Button>
                    </Center>
                  </Box>
                  <Box
                    p={10}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "65%" }}
                    my={5}
                  >
                    <Img borderRadius={"md"} src={lokalScreenshot} />
                  </Box>

                  <Box
                    p={10}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "65%" }}
                    my={5}
                  >
                    <Img borderRadius={"md"} src={mailPic} />
                  </Box>
                  <Box
                    p={10}
                    bg={"white"}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "90%", md: "60%", lg: "35%" }}
                    borderWidth={5}
                    my={5}
                  >
                    <Center>
                      <Heading size={"md"}>BUILD YOUR AUDIENCE</Heading>
                    </Center>
                    <Text>
                      Build your audience on Lokal, or bring them over from a
                      mailing list. On Lokal we use email to deliver your
                      content, start a newsletter to sell images of your art and
                      when you're ready you can add paid subscriptions.
                    </Text>
                    <Center>
                      <Button
                        onClick={onOpen}
                        bg={"#F6DD8F"}
                        fontWeight={"bold"}
                      >
                        CREATE YOUR PAGE
                      </Button>
                    </Center>
                  </Box>

                  <Box
                    p={10}
                    bg={"white"}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "90%", md: "60%", lg: "35%" }}
                    borderWidth={5}
                    my={5}
                  >
                    <Center>
                      <Heading size={"md"}>SELL COMMISSIONS</Heading>
                    </Center>
                    <Text>
                      Create a Commission Menu with all your products, set the
                      number of available slots and track the number of
                      commissions you've sold. When somebody makes a commission
                      request on your Lokal page, you receive an email with
                      their selection, any reference images, special notes and
                      their contact information.
                    </Text>
                  </Box>
                  <Box
                    p={10}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "65%" }}
                    my={5}
                  >
                    <Img borderRadius={"md"} src={menuPic} />
                  </Box>
                  <Box
                    p={10}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "65%" }}
                    my={5}
                  >
                    <Img borderRadius={"md"} src={gridPic} />
                  </Box>
                  <Box
                    p={10}
                    bg={"white"}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "90%", md: "60%", lg: "35%" }}
                    borderWidth={5}
                    my={5}
                  >
                    <Center>
                      <Heading size={"md"}>
                        CUSTOMIZE YOUR EMAIL CONTENT
                      </Heading>
                    </Center>
                    <Text>
                      Choose from our image layouts to give your subscribers the
                      best possible experience of your work.
                    </Text>
                    <Center>
                      <Button
                        onClick={onOpen}
                        bg={"#F6DD8F"}
                        fontWeight={"bold"}
                      >
                        CREATE YOUR PAGE
                      </Button>
                    </Center>
                  </Box>

                  <Box
                    p={{ base: 3, md: 10 }}
                    bg={"white"}
                    overflow={"hidden"}
                    borderRadius={"md"}
                    width={{ base: "100%", sm: "90%", md: "85%", lg: "80%" }}
                    my={5}
                  >
                    <Center>
                      <Button
                        onClick={onOpen}
                        bg={"#F6DD8F"}
                        size={"lg"}
                        fontWeight={"bold"}
                      >
                        CREATE YOUR PAGE
                      </Button>
                    </Center>
                  </Box>
                </Stack>
              </TabPanel>
              <TabPanel>
                <Center>
                  <Box p={2} m={2} bg={"#F6DD8F"} borderRadius={"md"}>
                    <Heading size={"md"}>ABOUT LOKAL</Heading>
                  </Box>
                </Center>
                <Box
                  width={{ base: "90%", md: "70%" }}
                  mx={"auto"}
                  bg={"white"}
                  p={10}
                  borderRadius={"md"}
                >
                  <Text>
                    At Lokal Creatives we make selling art as easy as possible.
                    We make products for creatives. Lokal makes it easy to run
                    your art business. We distribute your digital content, run
                    your memberships and process your art commissions. <br></br>
                    Use Lokal to have a direct relationship with your fans.
                    Everybody who subscribes to you receives all your content in
                    their email. We chose email because it remains the most
                    reliable way to get your content seen.<br></br> With Lokal
                    you don't need a bio link tool, yo u can share all your
                    social media links on your Lokal page.<br></br> Create your
                    Lokal page and get started. Just enter your information,
                    connect your payments account and you will get a personal
                    link to share your Lokal page.<br></br>
                    Lokal works by charging a percentage of your paid
                    subscriptions.Lokal is currently in beta, while continue to
                    develop features that make it easy to run your art business.
                    Start now and join our early users.
                  </Text>
                </Box>
              </TabPanel>
              <TabPanel>
                <Center>Blog coming soon.</Center>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <VerifyScreen />
      )}
    </Box>
  )
}
export default Start
