import { Center, Heading, Text, Box, Img } from "@chakra-ui/react"
import betaLogo from "../images/lokalcommissionslogobeta2.png"
import React from "react"

const Privacy = () => {
  return (
    <Box px={10} bg={"#F2D6D3"} overflow={"hidden"} minH={"100vh"}>
    <Img mt={7} boxSize={"106px"} src={betaLogo} />
      <Center>
        <Heading mt={5}>Privacy Policy</Heading>
      </Center>
      <Box width={{ base: "90%", md: "70%" }} mx={"auto"}>
        <Text>
          <Heading size="md">We respect your privacy</Heading> Lokal Creatives
          respects your right to privacy and is committed to safeguarding the
          privacy of our customers and website visitors. Your personal
          information may be collected via the Commissions by Lokal Creatives
          website https://art.lokalcr.com (the "Website") and associated
          subdomains of https://lokalcr.com/ or may be provided to us by third
          parties. By visiting and using our Website, you acknowledge and agree
          to the practices described in this Policy. For the purposes of this
          Privacy Policy, "personal information" is defined as information or
          data that Lokal Creatives has in its records which allows you as an
          individual to be identified directly or indirectly.{" "}
          <Heading size="md">How we collect and use your personal data</Heading>{" "}
          Commissions by Lokal Creatives is used by "Visitors", "Customers" and
          "Respondents." For the purpose of this Privacy Policy, Visitors are
          any persons who visit our Website, Customers are persons that create
          forms and Respondents are persons who answer or respond to those
          forms. In relation to the personal information of Customers, Lokal
          Creatives acts as a data controller for the purposes of the GDPR, as
          we determine the means and purposes of processing of that personal
          information. Where we process the personal information of Respondents,
          Lokal Creatives mostly acts as a data processor for the purposes of
          the GDPR, as we only process that personal information on behalf of
          the Customer and as instructed by the Customer. However, we may still
          gather certain types of information on Respondents in order to provide
          our Website and Services (as defined in the General Terms and
          Conditions of Use). For the purposes of the GDPR, you acknowledge that
          by providing your personal information to us, either as a Visitor,
          Customer or as a Respondent, that the collection of such personal
          information takes place outside the European Economic Area ("EEA") and
          that it is necessary for the provision of the Services to Process such
          personal information outside the EEA and that such personal
          information may then be stored on servers in the United States. In
          relation to the collection of such personal information you
          acknowledge that both these scenarios do not constitute a "transfer"
          of personal information from within the EEA to outside the EEA by us.
          Lokal Creatives will, from time to time, receive and store personal
          information entered onto our Website, provided to us directly or given
          to us in other forms. We may also collect information you provide
          while interacting with us. Lokal Creatives collects personal
          information from you in a variety of ways, including when you interact
          with us electronically or in person, when you access our website and
          when we provide our services to you. We may also receive personal
          information from third parties. If we do, we will protect it as set
          out in this Privacy Policy. Visitors: We may process personal
          information from Visitors on the basis of fulfilling our legitimate
          interests of improving and developing the quality of our products and
          services. Under this basis, we may collect such personal information
          from Visitors, including the following: (a) Usage data about the
          Visitors whenever the Visitors interacts with Lokal Creatives’
          Website. (b) Data from the device and application the Visitor uses to
          access Lokal Creatives’ Website, such as Visitor's IP address, browser
          type and operating system. (c) Information about the source that
          referred the Visitor to the Website such as a link on a website or in
          an e-mail. (d) Aggregated and other pseudonymous data about Visitors
          through the use of cookies, page tags and other tracking services. On
          the basis of fulfilling our legitimate interests in being able to
          respond to your questions, we may process such personal information as
          your name, email address, and/or other contact information. If we did
          not process your personal information in this manner, we would be
          unable to receive or provide answers to your questions. Customers: On
          the basis of fulfilling our contract with you, or the preparatory
          steps in relation to such contract, we may process such personal
          information as your name, phone number, address, email address, and/or
          financial or credit card information to enable us to send information,
          provide updates and process your product or service order. If we did
          not process your personal information in this manner, we would be
          unable to provide you with our products and services. On the basis of
          fulfilling our legitimate interests in the proper delivery of our
          products, services and communications to you as well as developing and
          growing our business, we process personal information in the following
          manner: We may request the collection of: feedback pertaining to your
          experience with Lokal Creatives’ Services and/or Website, information
          about the purpose for which you use our services, content changes or
          email preferences, or communications with our customer support. On the
          basis that you have provided your consent to have your personal
          information processed, we process your personal information in order
          to: send you promotional, advertising and other marketing materials or
          communications including newsletters, surveys and/or promotions. You
          may revoke your consent at any time by contacting us, as provided
          below. Revoking your consent will prevent us from processing your
          information for the purposes in which we sought consent, but will not
          affect any other processing of information or the other services we
          provide to you. Revoking your consent will also not affect the
          lawfulness of our processing of your personal information for the
          period before you withdrew your consent. Respondents: Respondents,
          when completing a form, may divulge personal information or data.
          Lokal Creatives is not responsible for the content of the Customer's
          form, and all processing of Respondents' personal information will be
          subject to the Customer's own privacy policy. The Customer is
          responsible for and manages the processing of data collected from the
          forms it creates and all Respondents and/or Customers who divulge
          personal information of third parties are solely responsible for
          obtaining the consent (if required) and notifying such third parties
          before divulging their personal information to us. Please contact the
          Customer directly if you have any questions about the form created by
          the Customer or the Customer's privacy policy. While we mostly process
          personal information on behalf of the Customer, we may still gather
          certain types of information on Respondents in order to provide our
          Services. The information we collect includes: the information
          Respondent enters into a form, before the Respondent completes or
          submits the form in order to prevent the inadvertent loss of
          Respondent's response; and Respondent's e-mail address if the Customer
          provides it to us to send the Respondent an invitation to complete a
          form by e-mail. If we did not process Respondent's information in this
          manner, we could not provide these features. We collect and store this
          information from Respondents to fulfill both our contract with the
          Consumer and our legitimate business interests in the proper delivery
          of our products and Services. We may also process additional personal
          information from Respondents on the basis of fulfilling our legitimate
          interests of improving and developing the quality of our products and
          Services, and better understanding your needs in an effort to provide
          our clients with the highest level of services. Under this basis, we
          may collect such additional personal information from Respondents,
          including the following: (a) Usage data about the Respondent whenever
          the Respondent interacts with Lokal Creatives’ Services. (b) Data from
          the device and application the Respondent uses to access Lokal
          Creatives’ Services, such as Respondent's IP address, browser type and
          operating system. (c) Information about the source that referred the
          Respondent to Lokal Creatives’ such as a link on a website or in an
          e-mail. (d) Aggregated and other pseudonymous data about Respondents
          through the use of cookies, page tags and other tracking services.{" "}
          <Heading size="md">How long we keep your personal data</Heading> Lokal
          Creatives’ will not hold your personal information for any longer than
          is necessary for the purposes for which it was obtained, unless it is
          otherwise necessary to hold your personal information to meet our
          legal requirements, as discussed above. For example, we may retain
          certain personal information to comply with regulatory requirements
          regarding the retention of such data. We shall periodically review and
          delete/erase personal information that we hold.{" "}
          <Heading size="md">Changes to this Privacy policy</Heading> Lokal
          Creatives reserves the right to review, alter, update and/or remove
          portions of this Privacy Policy in the future. We may modify this
          Privacy Policy at any time, in our sole discretion and all
          modifications will be effective immediately upon our posting of the
          modifications on our website or notice board. Please check back from
          time to time to review our Privacy Policy. By using our Website and/or
          the Services, you accept the version of this Privacy Policy in place
          at that time.{" "}
          <Heading size="md">Technology used to collect data</Heading> When you
          come to Lokal Creatives’ Website (https://lokalcr.com) or any
          subdomains of it, we may collect certain information, including,
          without limitation the type of browser used to access the Lokal
          Creatives’ Website, a users' operating system, the website visited
          immediately before coming to the Lokal Creatives’ Website. This
          information is used in an aggregated manner to analyze how people use
          the Lokal Creatives’ Website so that we can improve our Services.
          Lokal Creatives’ may use Cookies on the Website. Cookies are very
          small files which a website uses to identify you when you come back to
          the site and to store details about your use of the site. Cookies are
          not malicious programs that access or damage your computer. Most web
          browsers automatically accept cookies but you can choose to reject
          cookies by changing your browser settings. However, this may prevent
          you from taking full advantage of Lokal Creatives’ Website. Lokal
          Creatives’ Website may use Cookies to analyze website traffic and help
          provide a better website/visitor experience. In addition, Cookies may
          be used to display relevant ads to website visitors through third
          party services such as Google Adwords. These ads may appear on Lokal
          Creatives’ Website or third-party websites you visit. For information
          on how you can exercise your options regarding these advertisements,
          please see the below links: Facebook:
          https://www.facebook.com/help/568137493302217 Google:
          https://support.google.com/ads/answer/2662922?hl=en The Lokal
          Creatives Website may include links to third party websites. Lokal
          Creatives does not control nor does it have control over third party
          websites. These links are meant for your convenience only. Links to
          third party websites do not constitute sponsorship or endorsement or
          approval of these websites. By using the Lokal Creatives Website and
          Services, or by choosing to integrate the Services with third party
          services, you agree and acknowledge that Lokal Creatives is not
          responsible for the availability of third-party websites and is not
          responsible and/or liable for any content, advertising, products or
          other materials on or available from these third-party websites. You
          further acknowledge and agree that Lokal Creatives shall not be
          responsible and/or liable, directly, or indirectly, for any damage or
          loss caused or alleged to be caused by or in connection with your use
          of or reliance on any such content, goods or services available on or
          through any third-party website.
        </Text>
      </Box>
    </Box>
  )
}

export default Privacy
